import { BCard } from '@/components/bootstrap'
import Promocodes from '@/components/promocodes'
import { Vue, Component } from 'vue-property-decorator'
@Component
export default class PromocodesPage extends Vue {
  protected render() {
    return (
      <BCard no-body>
        <Promocodes />
      </BCard>
    )
  }
}
