import { BButton } from '@/components/bootstrap'
import DeleteConfirmationModal from '@/components/DeleteConfirmationModal'
import PromocodeModal from '@/components/promocodes/PromocodeEditModal'
import WayupTable, { Query } from '@/components/WayupTable'
import { CellData, Column, Sort } from '@/components/WayupTable/types'
import { promocodesStore } from '@/store'
import { IPromocode } from '@/store/promocodesStore'
import { Component, Ref, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

const PromocodeDetailsModal = require('@/components/promocodes/PromocodeDetailsModal.vue')
  .default //TODO

interface PromocodeProps {
  isModal?: boolean
  onSelect?: (promocode: IPromocode) => void
}

@Component
export default class Promocodes extends tsx.Component<PromocodeProps> {
  @Ref() readonly deleteConfirmationModal!: DeleteConfirmationModal
  @Ref() readonly promocodeModal!: PromocodeModal
  @Ref() readonly promocodeDetailsModal!: {
    show: (promocode: IPromocode) => void
  }

  @Prop({ type: Boolean, default: false })
  readonly isModal!: boolean

  initialSort: Sort<IPromocode> = {
    field: 'created',
    dir: 'desc',
  }
  initialPerPage = 20

  get columns(): Column<IPromocode>[] {
    const columns: Column<IPromocode>[] = [
      {
        field: 'id',
        sortable: true,
      },
      {
        field: 'promocode',
        title: 'Промокод',
        sortable: true,
      },
      {
        field: 'discount',
        title: 'Скидка',
        sortable: true,
        centered: true,
        formatter: value => `${value}%`,
      },
      {
        field: 'prepaymentAmount',
        title: 'Предоплата',
        sortable: true,
        centered: true,
        formatter: value => (value ? `${value}$` : '-'),
      },
      {
        field: 'courses',
        title: 'Курс',
        centered: true,
      },
      {
        field: 'usesCount',
        title: 'Использован',
        sortable: true,
        centered: true,
      },
      {
        field: 'fullPrice',
        title: 'Полная сумма',
        sortable: true,
        centered: true,
        formatter: value => `${value}$`,
      },
      {
        field: 'discountPrice',
        title: 'Сумма со скидкой',
        sortable: true,
        centered: true,
        formatter: value => `${value}$`,
      },
      {
        field: 'dateStart',
        title: 'Дата начала',
        sortable: true,
        type: 'dateTime',
        noWrap: true,
      },
      {
        field: 'dateEnd',
        title: 'Дата окончания',
        sortable: true,
        type: 'dateTime',
        noWrap: true,
      },
      {
        field: 'created',
        title: 'Создан',
        sortable: true,
        type: 'dateTime',
        noWrap: true,
      },
      {
        field: 'enabled',
        title: 'Статус',
        sortable: true,
        centered: true,
      },
      {
        customField: 'buttons',
        centered: true,
        noWrap: true,
      },
    ]

    if (this.isModal) {
      return [{ customField: 'selectButton' }, ...columns]
    }

    return columns
  }

  get promocodes() {
    return promocodesStore.promocodes
  }

  get promocodesCount() {
    return promocodesStore.promocodesCount
  }

  get pending() {
    return promocodesStore.pending
  }

  async mounted() {
    await promocodesStore.fetchPromocodes({
      page: 1,
      perPage: this.initialPerPage,
      filter: null,
      sort: this.initialSort,
      search: '',
    })
  }

  async onQueryChanged(query: Query) {
    await promocodesStore.fetchPromocodes(query)
  }

  async deletePromocode(promocode: IPromocode) {
    const result = await this.deleteConfirmationModal.show(promocode.promocode)

    if (result) {
      await promocodesStore.deletePromocode(promocode.id)
    }
  }

  renderSlot({ row, column, value }: CellData<IPromocode>) {
    if (column.customField === 'buttons') {
      return (
        <div>
          <BButton
            onClick={() => this.promocodeModal.show(row)}
            variant="outline-primary"
            class="mr-50 p-50"
          >
            <feather-icon icon="EditIcon" size="16" />
          </BButton>
          <BButton
            onClick={() => this.deletePromocode(row)}
            variant="outline-danger"
            class="p-50"
          >
            <feather-icon icon="TrashIcon" size="16" />
          </BButton>
        </div>
      )
    }

    if (column.customField === 'selectButton') {
      return (
        <BButton
          onClick={() => this.$emit('select', row)}
          variant="outline-primary"
        >
          Выбрать
        </BButton>
      )
    }

    switch (column.field) {
      case 'promocode':
        return (
          <b
            id={`promocode-${row.id}`}
            onClick={() => this.promocodeDetailsModal.show(row)}
            class="text-primary cursor-pointer"
          >
            {value}
            {!!row.description && (
              <b-popover
                target={`promocode-${row.id}`}
                triggers="hover"
                placement="right"
              >
                {row.description}
              </b-popover>
            )}
          </b>
        )
      case 'courses':
        return row.courses.length ? (
          <div class="d-flex justify-content-center flex-wrap min-width-200">
            {row.courses.map(course => (
              <b-badge variant="light-secondary" class="m-25" key={course.id}>
                {course.abbreviation}
              </b-badge>
            ))}
          </div>
        ) : (
          <div>Все курсы</div>
        )
      case 'enabled':
        return value ? (
          <b-badge variant="success">Активен</b-badge>
        ) : (
          <b-badge variant="danger">Отключен</b-badge>
        )
    }
  }

  protected render() {
    return (
      <div>
        <div
          class={['pb-1', { 'px-1': !this.isModal }, { 'pt-1': !this.isModal }]}
        >
          <BButton variant="primary" onClick={() => this.promocodeModal.show()}>
            Создать промокод
          </BButton>
        </div>
        <WayupTable
          rows={this.promocodes}
          columns={this.columns}
          totalRows={this.promocodesCount}
          perPage={this.initialPerPage}
          pending={this.pending}
          searchEnabled
          paginationEnabled
          onQueryChanged={this.onQueryChanged}
          sort={this.initialSort}
          scopedSlots={{ default: cellData => this.renderSlot(cellData) }}
        />
        <PromocodeModal ref="promocodeModal" />
        <DeleteConfirmationModal ref="deleteConfirmationModal" />
        <PromocodeDetailsModal ref="promocodeDetailsModal" />
      </div>
    )
  }
}
