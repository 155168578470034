<template>
  <b-modal
    id="promocode-details-modal"
    :title="title"
    centered
    hide-footer
    size="xl"
  >
    <b-input-group class="promocodes-search">
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input
        v-model="searchQuery"
        placeholder="поиск"
        type="text"
        autocomplete="false"
        @input="handleSearchInput"
      />
    </b-input-group>
    <vue-good-table
      :rows="activations"
      :columns="columns"
      :totalRows="totalRows"
      :pagination-options="paginationOptions"
      :isLoading.sync="isLoading"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-per-page-change="onPerPageChange"
      class="compact-good-table"
    >
      <div slot="emptystate">Не найдено использований</div>
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'course'">
          <b-media vertical-align="center" class="d-flex align-items-center">
            <template #aside>
              <b-aspect
                class="p-50 rounded d-flex align-items-center"
                aspect="1:1"
                :style="{
                  backgroundColor: props.row.course.color,
                }"
              >
                <b-img
                  class="rounded"
                  width="25"
                  height="25"
                  center
                  :src="props.row.course.previewUrl"
                  alt=""
                />
              </b-aspect>
            </template>
            <span>
              {{ props.row.course.abbreviation }}
            </span>
          </b-media>
        </div>

        <div v-else-if="props.column.field == 'email'">
          <b-media vertical-align="center" class="d-flex align-items-center">
            <template #aside>
              <b-avatar :src="props.row.avatarUrl" />
            </template>
            <span>
              {{ props.row.email }}
            </span>
          </b-media>
        </div>

        <div
          v-else-if="props.column.field == 'fullPrice'"
          class="d-flex justify-content-center"
        >
          {{ props.row.fullPrice }}$
        </div>

        <div
          v-else-if="props.column.field == 'discountPrice'"
          class="d-flex justify-content-center"
        >
          {{ props.row.discountPrice }}$
        </div>

        <div
          v-else-if="props.column.field == 'stream'"
          class="d-flex justify-content-center"
        >
          {{ props.row.stream.title }}
        </div>

        <div
          v-else-if="props.column.field == 'date'"
          class="d-flex justify-content-center"
        >
          {{ new Date(props.row.date).toLocaleString('ru') }}
        </div>

        <div v-else class="d-flex align-items-center justify-content-center">
          <div>
            {{ props.formattedRow[props.column.field] }}
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-modal>
</template>

<script>
import {
  BModal,
  BAspect,
  BMedia,
  BImg,
  BAvatar,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { showError } from '@/helpers/notifications'

export default {
  components: {
    BModal,
    BAspect,
    BMedia,
    BImg,
    BAvatar,
    VueGoodTable,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
  },
  data: () => ({
    promocode: null,
    title: '',
    totalRows: 0,
    isLoading: false,
    updateFilterTimeout: null,
    searchQuery: '',
    activations: [],
    columns: [
      { field: 'email', label: 'Студент' },
      { field: 'course', label: 'Курс' },
      { field: 'stream', label: 'Поток' },
      { field: 'fullPrice', label: 'Полная сумма' },
      { field: 'discountPrice', label: 'Сумма со скидкой' },
      { field: 'date', label: 'Дата' },
    ],
    paginationOptions: {
      enabled: true,
      mode: 'pages',
      perPageDropdown: [10, 50, 100],
      dropdownAllowAll: false,
      nextLabel: '',
      prevLabel: '',
      rowsPerPageLabel: 'на странице',
      ofLabel: 'из',
      pageLabel: 'страница',
    },
    tableOptions: {
      page: 1,
      perPage: 10,
      orderBy: null,
    },
  }),
  methods: {
    show(promocode) {
      this.promocode = promocode
      this.title = `Использования промокода "${promocode.promocode}"`
      this.$bvModal.show('promocode-details-modal')
      this.loadData()
    },
    onPageChange(params) {
      this.tableOptions.page = params.currentPage
      this.loadData()
    },

    onPerPageChange(params) {
      this.tableOptions.page = 1
      this.tableOptions.perPage = params.currentPerPage
      this.loadData()
    },

    onSortChange(params) {
      this.tableOptions.orderBy = params.map(
        sort => `{"${sort.field}":"${sort.type}"}`,
      )
      this.loadData()
    },

    handleSearchInput() {
      clearTimeout(this.updateFilterTimeout)
      this.updateFilterTimeout = setTimeout(this.updateSearch, 1000)
    },

    updateSearch() {
      this.isLoading = true
      this.tableOptions.page = 1
      this.loadData()
    },
    async loadData() {
      const { orderBy, page, perPage } = this.tableOptions

      try {
        const search = this.searchQuery
          ? `&searchQuery=${this.searchQuery}`
          : ''

        const order = orderBy ? `&orderBy=[${orderBy}]` : ''

        const query = `v1/promocodes/${this.promocode.id}?page=${page}&perPage=${perPage}${search}${order}`

        const response = await this.$axios.get(query)
        this.activations = response.data.students
        this.totalRows = response.data.meta.totalItems
      } catch {
        showError('При загрузке данных произошла ошибка')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>