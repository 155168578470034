import { Component, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import {
  BModal,
  BInput,
  BFormGroup,
  BOverlay,
  BTextarea,
} from '@/components/bootstrap'
import {
  FieldValidator,
  greater,
  lessOrEqual,
  maxLength,
  minLength,
  notEmpty,
  ValidationGroup,
} from '@/helpers/validation'
import DateTimePicker from '@/components/controls/DateTimePicker'
import { promocodesStore } from '@/store'
import { BvEvent } from 'bootstrap-vue'
import { ICourse, IPromocode, PromocodeData } from '@/store/promocodesStore'
import { Nullable } from '@/helpers/types/nullable'
import Select from '@/components/controls/Select'
import CourseSelectTemplate from '@/components/controls/Select/templates/Course'
import Switcher from '@/components/controls/Switcher'

@Component
export default class PromocodeModal extends tsx.Component<{
  show?: (promocode?: IPromocode) => Promise<void>
}> {
  modalId = 'promocode-modal'
  state: 'new' | 'edit' = 'new'
  promocodeId: Nullable<number> = null
  pending = false

  validator = new ValidationGroup()

  promocode = new FieldValidator({
    value: '',
    validators: [notEmpty(), minLength(2), maxLength(20)],
    group: this.validator,
  })

  discount = new FieldValidator<Nullable<number>>({
    value: null,
    validators: [notEmpty(), greater(0), lessOrEqual(100)],
    group: this.validator,
  })

  prepayment = new FieldValidator<Nullable<number>>({
    value: null,
    validators: [greater(0)],
    group: this.validator,
    ignoreValidation: value => {
      return !value && value !== 0
    },
  })

  startDate = new FieldValidator({
    value: '',
    validators: [notEmpty(), this.dateValidation],
    group: this.validator,
  })

  endDate = new FieldValidator({
    value: '',
    validators: [notEmpty(), this.dateValidation],
    group: this.validator,
  })

  description = ''
  isFullPaymentEnabled = true
  isInstallmentEnabled = true
  isPrepaymentEnabled = true

  dateValidation() {
    if (
      new Date(this.endDate.value!).getTime() -
        new Date(this.startDate.value!).getTime() <
      0
    ) {
      return { error: 'Дата окончания не может быть раньше даты начала' }
    }
  }

  selectedCourses: ICourse[] = []
  enabled = true

  get modalTitle() {
    if (this.state === 'new') {
      return 'Создать промокод'
    }
    return 'Редактировать промокод'
  }

  get okButtonTitle() {
    if (this.state === 'new') {
      return 'Создать'
    }
    return 'Сохранить'
  }

  get courses() {
    return promocodesStore.courses.filter(c => c.type === 'paid')
  }

  @Watch('startDate.value')
  @Watch('endDate.value')
  onDateChanged() {
    if (this.startDate.value) {
      this.startDate.validate()
    }

    if (this.endDate.value) {
      this.endDate.validate()
    }
  }

  async show(promocode?: IPromocode) {
    this.state = promocode ? 'edit' : 'new'
    this.$bvModal.show(this.modalId)

    this.pending = true

    await promocodesStore.fetchCourses()

    if (promocode) {
      this.fillData(promocode)
    }

    this.pending = false
  }

  fillData(promocode: IPromocode) {
    this.promocodeId = promocode.id
    this.promocode.set(promocode.promocode)
    this.discount.set(promocode.discount)
    this.startDate.set(promocode.dateStart)
    this.endDate.set(promocode.dateEnd)
    this.enabled = promocode.enabled
    this.description = promocode.description || ''
    this.prepayment.set(promocode.prepaymentAmount)
    this.isFullPaymentEnabled = promocode.isFullPaymentEnabled
    this.isInstallmentEnabled = promocode.isInstallmentEnabled
    this.isPrepaymentEnabled = promocode.isPrepaymentEnabled

    for (const course of promocode.courses) {
      const findedCourse = this.courses.find(c => c.id === course.id)
      if (findedCourse) {
        this.selectedCourses.push(findedCourse)
      }
    }
  }

  async submit(e: BvEvent) {
    e.preventDefault()

    if (!this.validator.validate()) {
      return
    }

    this.pending = true
    let result = false

    const data: PromocodeData = {
      id: this.promocodeId,
      promocode: this.promocode.value,
      courses: this.selectedCourses.map(c => c.id),
      dateStart: this.startDate.value,
      dateEnd: this.endDate.value,
      discount: this.discount.value!,
      enabled: this.enabled,
      description: this.description,
      prepaymentAmount: this.prepayment.value || null,
      isFullPaymentEnabled: this.isFullPaymentEnabled,
      isInstallmentEnabled: this.isInstallmentEnabled,
      isPrepaymentEnabled: this.isPrepaymentEnabled,
    }

    if (this.state === 'new') {
      result = await promocodesStore.createPromocode(data)
    } else {
      result = await promocodesStore.updatePromocode(data)
    }

    this.pending = false

    if (result) {
      this.$bvModal.hide(this.modalId)
    }
  }

  onHidden() {
    this.promocodeId = null
    this.promocode.set('')
    this.startDate.set('')
    this.endDate.set('')
    this.discount.set(null)
    this.enabled = true
    this.selectedCourses = []
    this.description = ''
    this.isFullPaymentEnabled = true
    this.isInstallmentEnabled = true
    this.isPrepaymentEnabled = true

    this.pending = false

    this.$nextTick(() => this.validator.resetErrors())
  }

  protected render() {
    return (
      <BModal
        id={this.modalId}
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        ok-title={this.okButtonTitle}
        cancel-title="Отмена"
        centered
        title={this.modalTitle}
        onHidden={this.onHidden}
        onOk={this.submit}
        busy={this.pending}
      >
        <BOverlay show={this.pending} no-wrap />

        <BFormGroup
          label="Текст промокода"
          state={this.promocode.hasError ? false : null}
          invalid-feedback={this.promocode.errorText}
        >
          <BInput
            value={this.promocode.value}
            onInput={this.promocode.set}
            placeholder="Введите текст"
            state={this.promocode.hasError ? false : null}
            formatter={value => value.toUpperCase()}
          />
        </BFormGroup>

        <BFormGroup label="Описание">
          <BTextarea
            value={this.description}
            onInput={value => (this.description = value)}
            placeholder="Введите описание"
          />
        </BFormGroup>

        <BFormGroup
          label="Скидка (%)"
          state={this.discount.hasError ? false : null}
          invalid-feedback={this.discount.errorText}
        >
          <BInput
            value={this.discount.value}
            onInput={this.discount.set}
            placeholder="Введите скидку"
            type="number"
            number
            state={this.discount.hasError ? false : null}
          />
        </BFormGroup>

        <BFormGroup
          label="Предоплата $"
          state={this.prepayment.hasError ? false : null}
          invalid-feedback={this.prepayment.errorText}
          description="Необязательно"
        >
          <BInput
            value={this.prepayment.value}
            onInput={this.prepayment.set}
            placeholder="Введите предоплату"
            type="number"
            number
            state={this.prepayment.hasError ? false : null}
          />
        </BFormGroup>

        <BFormGroup
          label="Начало действия (мск)"
          state={this.startDate.hasError ? false : null}
          invalid-feedback={this.startDate.errorText}
        >
          <DateTimePicker
            value={this.startDate.value}
            onInput={this.startDate.set}
            enableTime
            dateFormat="Y-m-d H:i:S"
            displayedFormat="l d M Y H:i"
          />
        </BFormGroup>

        <BFormGroup
          label="Окончание действия (мск)"
          state={this.endDate.hasError ? false : null}
          invalid-feedback={this.endDate.errorText}
        >
          <DateTimePicker
            value={this.endDate.value}
            onInput={this.endDate.set}
            enableTime
            dateFormat="Y-m-d H:i:S"
            displayedFormat="l d M Y H:i"
          />
        </BFormGroup>

        <BFormGroup
          label="Выберите курсы"
          description="Действует на все курсы, если ничего не выбрано"
        >
          <Select
            value={this.selectedCourses}
            onInput={value => (this.selectedCourses = value)}
            label="title"
            options={this.courses}
            emptyFilterMessage="нет подходящих курсов"
            placeholder="Выберите курс"
            elementDeclination={['курс', 'курса', 'курсов']}
            closeOnSelect={false}
            scopedSlots={{
              option: ({ option }: { option: ICourse }) => (
                <CourseSelectTemplate
                  title={option.title}
                  image={option.preview_url}
                  hex={option.preview_color}
                />
              ),
            }}
          />
        </BFormGroup>

        <Switcher
          value={this.enabled}
          onInput={value => (this.enabled = value)}
        >
          Промокод активен
        </Switcher>
        <Switcher
          value={this.isFullPaymentEnabled}
          onInput={value => (this.isFullPaymentEnabled = value)}
        >
          Полная оплата
        </Switcher>
        <Switcher
          value={this.isInstallmentEnabled}
          onInput={value => (this.isInstallmentEnabled = value)}
        >
          Рассрочка
        </Switcher>
        <Switcher
          value={this.isPrepaymentEnabled}
          onInput={value => (this.isPrepaymentEnabled = value)}
        >
          Предоплата
        </Switcher>
      </BModal>
    )
  }
}
